.dropdown-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;

    .dropdown-label {
        margin-top: 15px;
        margin-bottom: 15px;
        color: #231f20;
        font-size: 16px;
    }

    .dropdown-select {
        width: 100px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-size: 16px;
        text-align: center;
        overflow-y: auto;
        &:focus {
            outline: none;
            border-color: #0f88f2; // Example focus color
        }
    }
}
