.StartPage h1 {
    font-size: 24px !important;
}
.versionSelection {
    display: flex;

    .mobileversion,
    .webversion {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        color: black;
        text-decoration: none;
        font-size: 12px;
        margin: 80px auto;
        border: 2px solid transparent;
        padding: 18px;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            border-color: #004b8d;
            background-color: RGBA(0, 75, 141, 0.2);
        }

        img {
            width: 100%;
        }

        span {
            width: 100%;
            text-align: center;
        }
    }

    span {
        font-size: 1.2em;
        line-height: 2em;
    }

    @media (min-width: 768px) {
        .mobileversion,
        .webversion {
            width: 25%;
        }
    }
}
