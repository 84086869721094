.NyPasient {
    .start-new-test {
        display: block;
        position: relative;
        color: black;
        text-decoration: none;
        font-size: 12px;
        margin: 80px auto;
        border: 2px solid transparent;
        padding: 18px;
        background-color: transparent;

        &:hover {
            border-color: #004b8d;
            background-color: RGBA(0, 75, 141, 0.2);
        }

        span {
            display: block;
            font-size: 1.2em;
            line-height: 2em;
        }
    }
}
