.App {
    padding-top: 30px;
    text-align: center;

    main {
        // padding-top: 5px;
        // margin-top: 30px;
        margin-bottom: 100px;
    }
    * {
        box-sizing: border-box;
    }
    h1 {
        font-size: 14px;
        color: #004b8d;
        padding: 0 80px;
        font-weight: bold;

        @media only screen and (min-width: 624px) {
            font-size: 18px;
        }
    }
    button {
        cursor: pointer;
    }
    .left-float {
        float: left;
        text-align: left;
    }
    .right-float {
        float: right;
    }
    .txt-small {
        font-size: 13px;
        margin: 0;
    }
    .red-txt {
        color: #e31937;
        font-weight: bold;
    }
    .next-btn {
        background-color: #004b8d;
        color: white;
        padding: 10px 55px;
        border: none;
        border-radius: 20px;
        display: inline-block;
        margin: 20px 0;
        position: fixed;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        right: 0;
        width: 70vw;
        font-size: 16px;
        line-height: 20px;
        //text-transform: uppercase;
        text-decoration: none;

        &:disabled {
            background-color: lightgrey;
        }
    }
    .disable-txt-next {
        position: fixed;
        bottom: 64px;
        left: 0;
        right: 0;
        margin: 0 6px;
        font-size: 14px;
    }
    .sticky-top {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 30px;
    }
}
