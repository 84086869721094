.symptom-debut {
  h1 {
    margin-bottom: 60px;
  }

  .time-selects {
    margin-top: 24px;
    margin-bottom: 40px;
  }
}
