.slider-check-lbl {
  background-color: RGBA(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    position: relative;
    left: -99999px;
  }
  .slider-box {
    margin: 0 12px;
    padding: 12px 20px;
    background-color: gray;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: white;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      transition: left 1s;
    }
  }
  &.is-checked {
    .slider-box {
      background-color: darkseagreen;
      &:after {
        left: 19px;
      }
    }
  }
}
