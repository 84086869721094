.summary-page {
    h1 {
        color: white;
    }
    .grey-heading-section {
        background-color: #f2f2f2;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        padding: 6px 18px;
        margin: 0;
    }
    .summary-list-info {
        list-style-type: none;
        padding: 6px 18px;
        margin: 0;
        color: #231f20;

        .left {
            text-align: left;
        }
        .right {
            text-align: right;
        }
        .bold {
            font-weight: bold;
        }
        li {
            display: flex;
            justify-content: space-between;
        }
        .li-el-medicines {
            > * {
                flex-grow: 1;
            }
            .right {
                display: flex;
                flex-direction: column;
                font-size: 14px;
            }
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .score-sum {
        background-color: RGBA(0, 0, 0, 0.05);
        padding: 16px 0;
        position: relative;
        min-height: 90px;
        .bck-img {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 54px;
            max-height: 80px;
        }
        h3 {
            font-weight: normal;
            font-size: 22px;
            margin-bottom: 0;
        }
        .left-float,
        .right-float {
            padding: 0 12px;
            margin: 1rem 0 0;
            h3 {
                margin: 0;
            }
        }
        .sympttime {
            font-size: 22px;
            margin-top: 26px;
        }
        .rightCorner {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }
        .sdtime {
            font-size: 14px;
            margin-top: 30px;
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .toggleScoreImg {
        border: 0 none;
        background: none;
        color: #0f88f2;
        cursor: pointer;
        font-weight: bold;
    }

    .summary-list {
        list-style-type: none;
        text-align: left;
        padding-left: 0;
        width: 380px;
        max-width: 100%;
        margin: auto;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 0;
            .origin-sum {
                width: calc(100% - 140px);
            }
            img {
                width: 80px;
                margin-left: 12px;
            }
        }

        .score-number {
            float: right;
            font-weight: bold;
            padding-right: 12px;
        }

        .total-score {
            border-bottom: 1px solid gray;
            font-size: 20px;
            margin-bottom: 12px;
        }
    }

    &.hideScoreImg {
        .summary-list {
            li {
                border-bottom: 1px solid rgb(223, 223, 223);
                align-items: start;

                .origin-sum {
                    width: 80%;
                }
                img {
                    display: none;
                }
            }

            li:last-child {
                border-bottom: none;
            }
        }
    }
}
