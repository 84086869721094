.step-question {
    .step-task {
        margin: 0;
        margin-bottom: 8px;
        color: #231f20;
    }
    .answers-opt {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .answer-opts {
            width: 50%;
            outline: none;
            border: 1px solid gray;
            color: black;
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            position: relative;

            &.setwidth-50 {
                width: 50%;
            }
            &.setwidth-100 {
                width: 100%;
            }

            h2 {
                width: 100%;
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                bottom: 10px;
            }
            p,
            h2 {
                margin: 0.2em;
            }
            .score-points {
                position: absolute;
                width: 20px;
                line-height: 20px;
                top: 0;
                left: 0;
                margin: 4px 0 0;
                font-size: 12px;
                border-radius: 50%;
                background-color: white;
            }
            &.setwidth-100 {
                .score-points {
                    left: calc(50% - 10px);
                }
            }
            &.setwidth-50:nth-of-type(even) {
                .score-points {
                    left: calc(100% - 20px);
                }
            }

            .img-wrapper {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    max-width: 100%;
                    min-width: 150px;
                    height: 100%;
                    flex: 1 1 auto;
                    background-color: white;
                    border: none;
                    &.previously-selected {
                        background-color: RGBA(0, 0, 0, 0.1);
                    }
                    &.imgs-pl {
                        padding: 20px 10px 2.5em;
                        max-width: 50%;
                        position: relative;
                        &:nth-of-type(1):after {
                            content: '';
                            background-color: gray;
                            height: calc(100% - 3.5em);
                            width: 1px;
                            position: absolute;
                            top: 20px;
                            right: 0;
                        }
                    }
                    &:not(.imgs-pl) {
                        img {
                            width: 50%;
                        }
                    }
                    img {
                        padding-top: 20px;
                        padding-bottom: 4em;
                        width: 100%;
                        max-height: 200px;
                        object-fit: contain;
                    }
                    .opt-side-txt {
                        position: absolute;
                        bottom: 4em;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: black;
                    }

                    @media (hover: hover) {
                        &:hover {
                            background-color: lightgray;
                            cursor: pointer;
                        }
                    }

                    &:active {
                        background-color: lightgray;
                    }
                }
            }
            &:first-of-type {
                border-top: 1px solid gray;
            }
        }
    }
}
