.Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    z-index: 98;
    background-color: white;

    .hamburgermenu {
        position: fixed;
        top: 10px;
        right: 8px;
        border: none;
        background-color: transparent;
        margin: 0;
        padding: 0 22px 5px 10px;
        color: #004b8d;
        font-size: 12px;
        line-height: 15px;

        .menu-icn {
            border-top: 2px solid #004b8d;
            border-bottom: 2px solid #004b8d;
            position: absolute;
            right: 0;
            width: 20px;
            height: 14px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 20px;
                height: 2px;
                background-color: #004b8d;
            }
            &::before {
                top: 2px;
            }
            &::after {
                bottom: 2px;
            }
        }
        &.is-open {
            .menu-icn {
                border-width: 0;

                &:before {
                    transform: rotate(45deg);
                    top: 6px;
                }

                &:after {
                    transform: rotate(-45deg);
                    top: 6px;
                    bottom: unset;
                }
            }
        }
    }
    .btn-backstep {
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px;
        text-transform: capitalize;
        text-decoration: none;
        color: darkblue;
        border: none;
        background-color: transparent;

        &:before {
            content: '';
            border: solid darkblue;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            margin-bottom: 1px;
        }
    }

    .header-nav {
        z-index: 99;
        height: 100vh;
        overflow: auto;
        background-color: white;
        border-right-width: 0;
        position: fixed;
        left: 100%;
        right: 0;
        top: 0;
        padding: 32px 12px 12px;
        transition: 1s;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #e31937;
        }
        &::-webkit-scrollbar-track-piece {
            height: 30px;
            width: 30px;
        }
        &::-webkit-scrollbar-thumb {
            height: 30px;
            width: 8px;
            border: 2px solid #e31937;
            background: rgba(248, 245, 243, 0.9);
        }

        button.btn-end-session {
            color: white;
            background-color: #e31937;
            border-color: transparent;
            outline: none;
            text-decoration: none;
            padding: 12px;
            margin: 12px 0 22px;
            width: 100%;
        }
        .nihss-showhide,
        .url-step {
            display: block;
            padding: 10px 12px;
            width: 100%;
            position: relative;
            margin: 12px 0;
            &.no-margin-top {
                margin-top: 0;
            }
            &:not(:last-of-type):after {
                content: '';
                width: 2px;
                height: 12px;
                position: absolute;
                left: calc(50% - 1px);
                bottom: -12px;
                background-color: RGBA(0, 75, 141, 0.2);
            }
        }
        .nihss-showhide {
            background-color: transparent;
            border: 1px solid RGBA(0, 75, 141, 0.2);
            color: black;
            text-align: left;

            &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 18px solid #e31937;
                right: 15px;
                transition: 0.8s;
            }
            &.is-closed {
                &:before {
                    transform: rotate(-60deg);
                }
            }
            &.all-disabled {
                border-color: lightgrey;
                color: gray;
                &:before {
                    border-top: 18px solid lightgrey;
                }
            }
        }
        .nihhs-areaurls {
            transition: all 0.3s;
            overflow: auto;
            margin-left: 12px;
            .url-step:first-child {
                margin-top: 0;
            }
            &.show-area {
                max-height: 1000px;
            }
            &.hide-area {
                max-height: 0;
                overflow: hidden;
            }
            .url-step {
                &:after {
                    left: calc(50% - 7px);
                }
                &:last-of-type:after {
                    content: '';
                    width: 2px;
                    height: 12px;
                    position: absolute;
                    left: calc(50% - 7px);
                    bottom: -12px;
                    background-color: RGBA(0, 75, 141, 0.2);
                }
            }
        }
        .url-step {
            background-color: RGBA(0, 75, 141, 0.2);
            font-size: 14px;
            text-align: left;
            color: black;
            text-decoration: none;
            border: none;
            outline: none;

            &.is-nihss {
                padding-left: 32px;
            }
            .nihss-ref {
                width: 12px;
                position: absolute;
                left: 12px;
                font-weight: bold;
                text-align: left;
            }
            &:disabled {
                background-color: lightgrey;
                color: gray;
                &:not(:last-of-type):after {
                    background-color: lightgrey;
                }
            }
        }
        &.is-open {
            left: 0;
        }
        &.is-closed {
            .hamburgermenu {
                display: none;
            }
            .nihss-showhide:before {
                display: none;
            }
        }
    }
    @media only screen and (min-width: 624px) {
        .btn-backstep,
        .hamburgermenu {
            font-size: 18px;
        }
    }
}
