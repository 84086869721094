.history-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .history-menu {
        width: 30%;
        max-height: calc(20 * 1.5em);
        overflow-y: auto;
        background: #3a3a38;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        margin-bottom: 5px;

        .history-item {
            padding: 10px;
            width: 100%;
            color: #0f88f2;
            border-bottom: 1px solid #d4d4d4;
            cursor: pointer;
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                background: #555;
            }
        }
        .item-title {
            position: sticky;
            top: 0;
            padding: 10px;
            width: 100%;
            font-size: 12px;
            color: #bbbaba;
            border-bottom: 1px solid #d4d4d4;
            background-color: #3a3a38;
        }
    }

    .history-cancel {
        border-radius: 10px;
        padding: 10px;
        width: 30%;
        background-color: #494949;
        color: #d9534f;
        border: none;
        cursor: pointer;
        &:hover {
            background: #555;
        }
    }
}

.history-btn {
    padding: 10px;
    background-color: transparent;
    color: #0f88f2;
    border: none;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
