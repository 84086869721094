.VitaliaPage {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;

    .card {
        border: 1px solid #eaeaea;
        padding: 20px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        ul,
        ol {
            list-style-type: none;
            padding: 0; // Removes default padding
            margin: 0; // Removes default margin
        }
    }

    .vitalia-item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
            font-size: 1.25rem;
            margin: 0;
            color: #333;
        }

        .value {
            font-size: 1.5rem;
            color: #333;
        }

        .bp-input {
            label {
                position: relative;
                input {
                    font-size: 16px;
                    padding: 4px 16px;
                    margin: 0 22px;
                    border: 1px solid RGBA(0, 0, 0, 0.5);
                    background-color: white;
                    max-width: 100px;

                    &:disabled {
                        background-color: RGBA(0, 0, 0, 0.1);
                        border-color: RGBA(0, 0, 0, 0.1);
                    }
                }
                .slash:after {
                    content: '';
                    position: absolute;
                    height: 26px;
                    background-color: gray;
                    width: 2px;
                    transform: rotate(25deg);
                }
                .comma:after {
                    content: ',';
                    position: absolute;
                    font-size: 20px;
                    color: gray;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .VitaliaPage {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}
