.pasient-question {
    .pasient-task {
        margin: 0;
        margin-bottom: 8px;
        color: #231f20;
    }
    .answers-opt {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .answer-opts {
            width: 50%;
            outline: none;
            border: 1px solid gray;
            color: black;
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            position: relative;

            h2 {
                width: 100%;
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                bottom: 10px;
            }
            p,
            h2 {
                margin: 0.2em;
            }

            .img-wrapper {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    max-width: 100%;
                    min-width: 150px;
                    height: 100%;
                    flex: 1 1 auto;
                    background-color: white;
                    border: none;
                    &.previously-selected {
                        background-color: RGBA(0, 0, 0, 0.1);
                    }
                    &:not(.imgs-pl) {
                        img {
                            width: 50%;
                        }
                    }
                    img {
                        padding-top: 20px;
                        padding-bottom: 4em;
                        width: 100%;
                        max-height: 200px;
                        object-fit: contain;
                    }
                    &:hover {
                        background-color: lightgray;
                        cursor: pointer;
                    }
                }
            }
            &:first-of-type {
                border-top: 1px solid gray;
            }
        }
    }
}
