.InputMultipleSelect {
  border: none;
  padding: 0;

  .areaname-hidden {
    display: none;
  }

  .mutiple-check-lbl {
    display: block;
    position: relative;
    max-width: 400px;
    text-align: left;
    margin: 2px auto;
    padding: 4px 12px;
    padding-left: 22px;

    input {
      position: relative;
      left: -99999px;
      &:disabled {
        &+span {
          color: gray;
        }
      }
    }
    &:after {
      content: '';
      padding: 0;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 8px;
      border: 1px solid gray;
      border-radius: 50%;
    }
    &.is-checked {
      background-color: RGBA(0, 0, 0, 0.1);
      &:after {
        border-color: #004B8D;
        background-color: #004B8D;
        background-image: url('../cssimgs/done_white_24dp.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
      }
    }
    &.is-disabled {
      &:after {
        border-color: lightgrey;
        background-color: lightgrey;
      }
    }
  }
}