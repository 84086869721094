.LegemidlerPage {
    .legemiddelheader {
        font-weight: bold;
        font-size: 16px;
        margin: 6px 0 12px;
        width: 100%;
        background-color: RGBA(0, 0, 0, 0.1);
        margin: 0;
        padding: 12px 4px 8px;
    }

    .scrollarea-45 {
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #004b8d;
        }
        &::-webkit-scrollbar-track-piece {
            height: 30px;
            width: 30px;
        }
        &::-webkit-scrollbar-thumb {
            height: 30px;
            width: 8px;
            border: 2px solid #004b8d;
            background: rgba(248, 245, 243, 0.9);
        }
    }
    .inputfield-txt {
        margin: 0 auto 12px;
        input {
            font-size: 14px;
        }
    }
}
