.time-selects {
  padding: 10px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  max-width: 600px;

  > * {
    flex-grow: 1;
  }
  .legend-txt {
    font-weight: bold;
    font-size: 14px;
    margin: 6px 0 12px;
    width: 100%;
  }
  .hours-wrapper {
    display: inline-flex;
    > .select-lbl {
      flex-grow: 1;
    }
  }
  .select-lbl {
    margin: 0 2px;
    select {
      width: 100%;
      font-size: 24px;
      padding: 8px 22px;
      border: 1px solid RGBA(0, 0, 0, 0.5);
      background-color: white;
      &:disabled {
        border: none;
        background-color: RGBA(0, 0, 0, 0.05);
      }
    }
    span {
      display: block;
      text-align: left;
      padding-left: 24px;
      font-size: 12px;
      color: gray;
    }
  }
  @media only screen and (min-width: 624px) {
    margin-left: auto;
    margin-right: auto;
  }
}
