.inputfield-txt {
  display: block;
  max-width: 400px;
  margin: 40px auto;
  padding: 0 12px;
  input {
    width: 100%;
    font-size: 24px;
    padding: 8px 22px;
    border: 1px solid RGBA(0, 0, 0, 0.5);
    background-color: white;
    &:disabled {
      border-color: RGBA(0, 0, 0, 0.05);
      background-color: RGBA(0, 0, 0, 0.05);
    }
  }
  span {
    display: block;
    text-align: left;
    padding-left: 24px;
    font-size: 12px;
    color: gray;
  }
}